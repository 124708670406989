import { getQueryParams } from './../utils/Qs';
import { useEffect, useState } from "react";

export const useParams = ()=> {
    const [params, setParams] = useState<any>();

    useEffect(() => {
        const result = getQueryParams();
        setParams(result);
    },[location.href]);

    return params;
}

export default useParams